import { DateTime, Duration, IANAZone } from "luxon"

// TODO: Make this function return an ISO formatted string
export function getDates(startDate, withWeekend, withWeek, memberTimezone) {
  let counter
  let indices = []
  for (counter = 0; counter < 5; counter++) indices.push(counter)
  if (withWeekend) indices.push(counter)
  counter++
  if (withWeekend) indices.push(counter)
  counter++
  if (withWeek) {
    let i
    for (i = counter; i < counter + 5; i++) indices.push(i)
    counter = i
    if (withWeekend) indices.push(counter)
    counter++
    if (withWeekend) indices.push(counter)
    counter++
  }
  // Handles timezones where the week starts at sunday
  if (DateTime.fromISO(startDate).setZone(memberTimezone).weekday === 7) {
    startDate = DateTime.fromISO(startDate).setZone(memberTimezone).plus({ days: 1 }).toISO()
  } else if(DateTime.fromISO(startDate).setZone(memberTimezone).weekday === 2) {
    startDate = DateTime.fromISO(startDate).setZone(memberTimezone).minus({ days: 1 }).toISO()
  }
  return indices.map((amountOfDays) => DateTime.fromISO(startDate).setZone(memberTimezone).plus({ days: amountOfDays }).startOf("day"))
}

export function buildHours(isExtended, minMemberHeight, maxMemberHeight) {
  const stepSize = isExtended ? 0.25 : 1
  let times = []
  let time = minMemberHeight
  while (time + stepSize <= maxMemberHeight) {
    times.push(time)
    time += stepSize
  }
  return times
}

// Return the minimum and maximum offset in minutes, for the given dates, on the
// specified timezone.
function getZoneOffsets(dates, timezone, locationTimezone) {
  const zone = IANAZone.create(timezone)
  const locationZone = IANAZone.create(locationTimezone)
  // const localZone = new LocalZone()
  const offsets = dates.map((date) => date.toMillis()).map((ts) => locationZone.offset(ts)-zone.offset(ts))
  return [Math.min(...offsets), Math.max(...offsets)]
}

// Calculate how many hours should be visible
export function getTaskInterval(items, dates, dateStartTimestamps, workHours, timezone, locationTimezone, extended, dateToItemsMap) {
  const [smallestOffset, largestOffset] = getZoneOffsets(dates, timezone, locationTimezone);

  let minMemberHeight = Duration.fromObject({ seconds: workHours.min }).plus({ minutes: smallestOffset });
  let maxMemberHeight = Duration.fromObject({ seconds: workHours.max }).plus({ minutes: largestOffset });

  if (workHours.min > workHours.max) {
    maxMemberHeight = maxMemberHeight.plus({ days: 1 });
  }

  const itemSize = extended ? Duration.fromObject({ minutes: 15 }).as('milliseconds') : Duration.fromObject({ hours: 1 }).as('milliseconds');

  dates.forEach((date, index) => {
    const currentDateString = date.toISODate();
    const { starts, ends } = dateToItemsMap[currentDateString] || { starts: [], ends: [] };

    // Process starts
    starts.forEach(item => {
      const startTimestamp = item.startDateTime.toMillis();
      const datePlusMin = date.plus(minMemberHeight).toMillis();
      const datePlusMax = date.plus(maxMemberHeight).toMillis();

      const diffFromStart = datePlusMin - startTimestamp;

      if (diffFromStart > 0) {
        minMemberHeight = minMemberHeight.minus(Duration.fromMillis(diffFromStart));
      }

      const diffFromEnd = startTimestamp - datePlusMax;
      if (diffFromEnd > 0) {
        maxMemberHeight = maxMemberHeight.plus(Duration.fromMillis(diffFromEnd));
      }
    });

    // Process ends
    ends.forEach(item => {
      const endTimestamp = item.endDateTime.minus({ milliseconds: 1 }).toMillis();
      const datePlusMin = date.plus(minMemberHeight).toMillis();
      const datePlusMax = date.plus(maxMemberHeight).toMillis();

      const diffFromStart = (datePlusMin - endTimestamp) + (item.startDate !== item.endDate ? itemSize : 0);
      if (diffFromStart > 0) {
        minMemberHeight = minMemberHeight.minus(Duration.fromMillis(diffFromStart));
      }

      const diffFromEnd = endTimestamp - datePlusMax;
      if (diffFromEnd > 0) {
        maxMemberHeight = maxMemberHeight.plus(Duration.fromMillis(diffFromEnd));
      }
    });
  });

  if (minMemberHeight < Duration.fromObject({}) || maxMemberHeight > Duration.fromObject({ days: 1 })) {
    minMemberHeight = Duration.fromObject({});
    maxMemberHeight = Duration.fromObject({ days: 1 });
  }

  return extended
    ? [
        Math.floor(minMemberHeight.as("hours") * 4) / 4,
        Math.ceil(maxMemberHeight.as("hours") * 4) / 4
      ]
    : [
        Math.floor(minMemberHeight.as("hours")),
        Math.ceil(maxMemberHeight.as("hours"))
      ];
}
// TODO: Don't assume input DateTime?
export function getWidth(date, scheduleObjects, minutes) {
  // Generate initial res
  const currMaxWidth = 90
  const res = scheduleObjects.map((obj) => ({
    width: 0,
    maxWidth: currMaxWidth,
    left: null,
    obj,
  }))

  // Generate an array containing indicies to res, depending on whether they're positioned a specific minute
  const nbs = Array.from({ length: minutes }, () => [])
  res.forEach((item, idx) => {
    const timeStart = Math.max(DateTime.fromISO(item.obj.start).diff(date, ["minutes", "seconds"]).minutes, 0)
    const timeEnd = Math.min(DateTime.fromISO(item.obj.end).diff(date, ["minutes", "seconds"]).minutes, nbs.length)
    for (let i = timeStart; i < timeEnd; i++) {
      nbs[i].push(idx)
    }
  })

  // can still be optimized but the current version is efficient and does the job alright
  // should use maxHeap (amount of nondetermined tasks) but no available library
  // break if theres more than 20 iterations to avoid endless loop
  var iterations = 0
  while (iterations < 20) {
    let maxIndex = -1
    let maxAmount = 0
    let maxAmountDone = 0
    let maxAvailableWidth = 0
    iterations += 1
    nbs.forEach((indices, idx) => {
      let amountDone = 0
      let availableWidth = 90
      indices.forEach((index) => {
        if (res[index].width) {
          // not determined yet
          amountDone++
          availableWidth -= res[index].width
        }
      })
      if (
        indices.length - amountDone > 0 &&
        (indices.length > maxAmount || (indices.length === maxAmount && amountDone > maxAmountDone))
      ) {
        maxAmountDone = amountDone
        maxAmount = indices.length
        maxIndex = idx
        maxAvailableWidth = availableWidth
      }

    })

    if (maxIndex === -1 ) {
      break
    }

    // not every task has had width determined
    let availablePositions = []
    for (let i = 5; i <= 95; i++) {
      availablePositions.push(true)
    }
    nbs[maxIndex].forEach((index) => {
      const item = res[index]
      if (item.width) {
        for (let j = item.left; j < item.left + item.width; j++) {
          availablePositions[j] = false
        }
      }
    })
    nbs[maxIndex].forEach((index) => {
      const item = res[index]
      if (!item.width) {
        let c = 0
        for (let i = 0; i < 91; i++) {
          if (availablePositions[i]) {
            c++
          } else {
            c = 0
          }
          if (c > item.width) {
            item.width = c
            item.left = i - c + 1
          }
        }
        item.width = Math.min(item.width, maxAvailableWidth / (maxAmount - maxAmountDone))
        for (let j = item.left; j <= item.width + item.left; j++) {
          availablePositions[j] = false
        }
      }
    })
  }

  res.forEach((item) => {

    if (item.width === 0) {
      // Ensure that a width is set
      item.width = currMaxWidth
    }
    item.left += 5
  })
  return res
}

export function chooseOptimalColor(backgroundColor) {
  if (!backgroundColor) {
    // The background color isn't set, so is probably set in stylesheets
    return undefined
  }
  try {
    const r = window.parseInt(backgroundColor.substring(1, 3), 16)
    const g = window.parseInt(backgroundColor.substring(3, 5), 16)
    const b = window.parseInt(backgroundColor.substring(5, 7), 16)
    const sum = r + g + b
    const limit = (255 * 3) / 2
    if (sum > limit) {
      return "black"
    } else {
      return "white"
    }
  } catch (err) {
    console.warn("Failed loading color", err)
    // Default to stylesheet-defined color
    return undefined
  }
}
// TODO: Refactor these 2 functions into one
export function optimalColor(nodeColor, darkColor="black", lightColor="white") {
  // strip the leading '#' from the hexadecimal string
  if (nodeColor[0] === '#') nodeColor = nodeColor.substr(1);

  let r = parseInt(nodeColor.substr(0, 2), 16);
  let g = parseInt(nodeColor.substr(2, 2), 16);
  let b = parseInt(nodeColor.substr(4, 2), 16);

  // Calculate the perceived brightness using the standard luminance formula
  let brightness = Math.round(0.299*r + 0.587*g + 0.114*b);

  // Decide the color of the icon based on the brightness of the node
  // This threshold can be adjusted to get the best result, 128 is in the middle of 0-255
  return brightness > 128 ? darkColor : lightColor;
}

// true if on dark background, Only hex values
export function isColorDark(BackgroundColor) {
  const col = BackgroundColor.charAt(0).toUpperCase()
  return col.match(/[0|1|2]/)
}

// true if light background, Only hex values
export function isColorLight(BackgroundColor) {
  const col = BackgroundColor.charAt(0).toUpperCase()
  return col.match(/[F]/)
}

// Converts the timezone in a DateTime without changing the hour
export function convertOffset(iso, newTimezone) {
  const sourceTime = DateTime.fromISO(iso);

  // Create an IANAZone object for the target timezone
  const targetZone = IANAZone.create(newTimezone);

  // Change the timezone of the DateTime object to the target timezone while keeping the local time
  const targetTime = sourceTime.setZone(targetZone, { keepLocalTime: true }).startOf("day");

  return targetTime
}

export function CheckDates(currentDate, dateFrom, dateTo) {
  const currentDay = currentDate.startOf("day")
  const fromDay = dateFrom.startOf("day")
  const toDay = dateTo.startOf("day")

  if(currentDay >= fromDay && currentDay <= toDay) {
    return true
  } else {
    return false
  }
}

// compare DateTime objects by year, month and day
export function isSameDate(currentDate, dateToCheck) {
  return (
    currentDate.year === dateToCheck.year &&
    currentDate.month === dateToCheck.month &&
    currentDate.day === dateToCheck.day
  )
}
