import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { DateTime } from "luxon"
import { useDrop } from "react-dnd"

import useCustomDrag from "utils/scripts/useCustomDrag"
import dragTypes from "utils/scripts/dragTypes"
import { chooseOptimalColor } from "utils/scripts/schedule"
import { Popup, SampleTable, Loading } from "components"
import { FiAlertTriangle } from "react-icons/fi"
import { analyses, sampleView } from "state_management"
import { Text } from "components/Form"

import "./index.scss"
import { SampleTableForUnplannedAnalysis } from "components/SampleTable"

function AnalysisItem({ name, minSampleDeadline, maxSampleArrival, sampleCount, iapWarning, title, color, onClick }) {
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const iapFromDate = DateTime.fromISO(useSelector((state) => state.teamStatus.iapFromDate)).setZone(memberTimezone)
  const isOverDeadline = DateTime.fromISO(minSampleDeadline).setZone(memberTimezone) < iapFromDate
  return (
    <div
      className="AnalysisTasksMenu-analysis-container"
      title={title}
      onClick={onClick}
      style={{
        boxShadow: isOverDeadline ? "0px 0px 0px 6px red" : "0",
        marginBottom: isOverDeadline ? "1.2rem" : "",
        color: color ? chooseOptimalColor(color) : "black",
        backgroundColor: color ? color : "white",
      }}
    >
      {iapWarning && iapWarning.length > 0 && (
        <div title={iapWarning} className="hoverable-container">
          <div className="yellow-warning-container">
            <FiAlertTriangle />
          </div>
        </div>
      )}
      <div className="AnalysisTasksMenu-p-container">
        <span style={{ fontSize: "1.2rem" }}>{name}</span>
      </div>
      <div className="AnalysisTasksMenu-p-container">
        <p>Deadline: {minSampleDeadline ? minSampleDeadline.toFormat("ff") : "?"}</p>
      </div>
      <div className="AnalysisTasksMenu-p-container">
        <p>Earliest start: {maxSampleArrival ? maxSampleArrival.toFormat("ff") : "?"}</p>
      </div>
      {sampleCount && (
        <div className="AnalysisTasksMenu-p-container">
          <p>Amount of samples: {sampleCount}</p>
        </div>
      )}
    </div>
  )
}

function DraggableAnalysisItem({ analysis, ...props }) {
  const { isDragging, dragRef } = useCustomDrag(props.isAltPressed ? dragTypes.SMART_DRAG : dragTypes.ANALYSIS, analysis)
  return (
    <div ref={dragRef} style={{ opacity: isDragging ? "0.4" : undefined }}>
      <AnalysisItem {...props} />
    </div>
  )
}

function unplannedAnalysisRegex(unplannedAnalysis, searchText) {
  searchText = searchText.toLowerCase()
  // Fancy regex expression to place a \ in front of all special characters
  searchText = searchText.replace(/[.$&+,:;=?@#|'<>-^*()%!]/g, "\\$&")
  const re = new RegExp(searchText, "i")
  const str = unplannedAnalysis.name + " " + unplannedAnalysis.description + " "
  return str.match(re)
}

function searchInSampleGroups(sampleGroups, searchText) {
  if (!sampleGroups) {
    return null;
  }

  searchText = searchText.toLowerCase();
  searchText = searchText.replace(/[.$&+,:;=?@#|'<>-^*()%!]/g, "\\$&");
  const re = new RegExp(searchText, "i");

  let matchingAnalyses = [];

  for (const sampleGroup of sampleGroups) {
    if (sampleGroup.sampleName && sampleGroup.sampleName.match(re)) {
      // If the sampleName matches, push all its analyses
      matchingAnalyses = matchingAnalyses.concat(sampleGroup.analyses);
    }
  }

  return matchingAnalyses.length > 0 ? matchingAnalyses : null;
}

function sampleGroupsRegex(sampleGroup, searchText) {
  searchText = searchText.toLowerCase()
  searchText = searchText.replace(/[$&+,:;=?@#|'<>.-^*()%!]/g, "\\$&")
  const re = new RegExp(searchText, "i")
  const str = sampleGroup
  return str.match(re)
}

function UnassignedSamples({ setActivePopupSamples, filter }) {
  const dispatch = useDispatch()
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const unassignedSamples = useSelector((state) => state.analyses.unassignedSamples)
  const isLoading = useSelector((state) => state.analyses.isLoadingUnassignedSamples)

  useEffect(() => {
    if (!isLoading && !unassignedSamples) {
      dispatch(analyses.fetchUnassignedSamples())
    }
  })

  if (isLoading || !unassignedSamples) {
    return <Loading />
  }

  if (unassignedSamples.length === 0) {
    return null
  }

  // Group samples by analysisName
  let sampleGroups = unassignedSamples.reduce(
    (acc, sample) => ({
      ...acc,
      [sample.analysisName]: (acc[sample.analysisName] || []).concat(sample),
    }),
    {}
  )

  sampleGroups = Object.entries(sampleGroups).filter((sample) => sampleGroupsRegex(sample[0], filter))

  return (
    <>
      {sampleGroups.length !== 0 && <p className="Pheading">Unassigned samples</p>}
      <div className="unassigned-samples-container">
        {sampleGroups.map(([analysisName, samples]) => (
          <AnalysisItem
            key={analysisName}
            name={analysisName}
            minSampleDeadline={samples.reduce(
              (minDeadline, sample) =>
                minDeadline
                  ? minDeadline < DateTime.fromISO(sample.deadline).setZone(memberTimezone)
                    ? minDeadline
                    : DateTime.fromISO(sample.deadline).setZone(memberTimezone)
                  : DateTime.fromISO(sample.deadline).setZone(memberTimezone),
              null
            )}
            sampleCount={samples.length}
            title={`Unassigned samples for "${analysisName}"`}
            onClick={() => setActivePopupSamples({ analysisName, samples })}
          />
        ))}
      </div>
    </>
  )
}

export default function AnalysisTasksMenu({ showTitle }) {
  const unplannedAnalyses = useSelector((state) => state.analyses.unplannedAnalyses)
  const plannedAnalyses = useSelector((state) => state.analyses.plannedAnalyses)
  const sortedUnplannedAnalysis = useSelector((state) => state.analyses.unplannedAnalysesSorted)
  const waiting = useSelector((state) => state.waiting.sampleAllocating)
  const sampleGroups = useSelector((state) => state.sampleView.sampleGroups)
  const sortingParameter = useSelector((state) => state.teamStatus.sortingParameter)
  const [activePopupAnalysisId, setActivePopupAnalysisId] = useState(null)
  const [activePopupSamples, setActivePopupSamples] = useState(null)
  const [textfilter, setTextFilter] = useState("")
  const [isAltPressed, setIsAltPressed] = useState(false);
  const dispatch = useDispatch()
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: dragTypes.ANALYSIS_TASK,
      collect: (mon) => ({
        isOver: !!mon.isOver(),
      }),
      drop: ({ item }) => {
        dispatch(analyses.remove(item.analysisId)).then(() => {
          dispatch(sampleView.fetch())
        })
      },
    }),
    [dragTypes.ANALYSIS_TASK, plannedAnalyses]
  )

  useEffect(() => {
    const handleMouseMove = (event) => {
      setIsAltPressed(event.altKey);
    };

    const handleKeyDown = (event) => {
      if (event.key === "Alt") {
        setIsAltPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Alt") {
        setIsAltPressed(false);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const RenderUnplannedAnalysis = () => {
    const sortedFilteredUnplannedAnalysis = sortedUnplannedAnalysis.filter((unAnalyses) => {
      if (!textfilter) return true;

      const analysisMatch = unplannedAnalysisRegex(unAnalyses, textfilter);
      const sampleGroupMatch = searchInSampleGroups(sampleGroups, textfilter);

      const hasSampleGroupNameMatch = sampleGroupMatch ? sampleGroupMatch.some(sampleGroup => sampleGroup.analysisId === unAnalyses.id) : null;

      return (analysisMatch && analysisMatch.length > 0) || hasSampleGroupNameMatch;
    });


    // Do not need sorting for deadline since it is already sorted from BE, and it rereenders when the IAP priority is changed
    if (sortingParameter === "PRIORITY") {
      sortedFilteredUnplannedAnalysis.sort((a, b) => a.minPriority - b.minPriority)
    } else if (sortingParameter === "DEADLINE") {
      sortedFilteredUnplannedAnalysis.sort((a, b) => {
        const dateA = new Date(a.minSampleDeadline);
        const dateB = new Date(b.minSampleDeadline);
        return dateA - dateB;
      });
    }

    return sortedFilteredUnplannedAnalysis.map((analysis) => (
      <DraggableAnalysisItem
        key={analysis.id}
        analysis={analysis}
        iapWarning={analysis.iapWarning}
        name={analysis.name}
        minSampleDeadline={DateTime.fromISO(analysis.minSampleDeadline).setZone(memberTimezone)}
        maxSampleArrival={DateTime.fromISO(analysis.maxSampleArrival).setZone(memberTimezone)}
        sampleCount={analysis.sampleCount}
        title={analysis.description}
        color={analysis.color}
        onClick={() => setActivePopupAnalysisId(analysis.id)}
        isAltPressed={isAltPressed}
      />
    ))
  }

  return (
    <div
      className="AnalysisTasksMenu-main-container"
      ref={drop}
      style={{
        filter: isOver ? "brightness(50%)" : "none",
        pointerEvents: waiting ? 'none' : 'auto'
      }}
    >
      {activePopupAnalysisId && (
        <Popup onCancel={() => setActivePopupAnalysisId(null)} title={unplannedAnalyses[activePopupAnalysisId].name}>
          <SampleTableForUnplannedAnalysis analysisId={activePopupAnalysisId} />
        </Popup>
      )}
      {activePopupSamples && (
        <Popup
          onCancel={() => setActivePopupSamples(null)}
          title={`Samples with the name ${activePopupSamples.analysisName}`}
        >
          <SampleTable popupSamples={activePopupSamples.samples} />
        </Popup>
      )}
      <div>
        {showTitle && <p className="Pheading">Analyses</p>}
        <Text value={textfilter} onChange={setTextFilter} placeholder="Filter by Text"></Text>
      </div>
      {
        //showAnalysisFilterConfig && (
        //<FilterAnalysisConfig cancelHandler={() => setShowAnalysisFilterConfig(false)}></FilterAnalysisConfig>
        //
      }
      <div className="AnalysisTasksMenu-tasks-container">{unplannedAnalyses && <RenderUnplannedAnalysis />}</div>
      <UnassignedSamples setActivePopupSamples={setActivePopupSamples} filter={textfilter} />
    </div>
  )
}
