// The main entry point for the SPA

import React, { useEffect, useCallback } from "react"
import { Route, Navigate, Routes, useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import { DndProvider, TouchTransition, MouseTransition } from "react-dnd-multi-backend"

import DOMPurify from "dompurify"
import "./App.scss"
import { Settings } from 'luxon'

import { CustomDragLayer, Loading, ChatBot, Popup } from "components"
import SetupScreen from "screens/SetupScreen"
import ScheduleScreen from "screens/ScheduleScreen"
import SingleMemberScheduleScreen from "screens/ScheduleScreen/SingleMemberScheduleScreen"
import ManageScreen from "screens/ManageScreen"
import LoginScreen from "screens/LoginScreen"
import AssetViewScreen from "screens/AssetViewScreen"
import SampleViewScreen from "screens/SampleViewScreen"
import ScheduleScreenMobile from "screens/ScheduleScreenMobile"
import AdministratorScreen from "screens/AdministratorScreen"
import CustomerAdministratorScreen from "screens/CustomerAdministratorScreen"
import ScrumViewScreen from "screens/ScrumViewScreen"

import ProjectViewScreen from "screens/ProjectViewScreen"

import { loading as loadingState, teamStatus, auth } from "state_management"

import isOnMobile from "utils/scripts/isOnMobile"
import LibrarianScreen from "screens/LibrarianScreen"
import SuiteScreen from "screens/SuiteScreen"
import useInactivityTracker from "utils/hooks/useInactivityTracker"
import { displayWithLineBreaks } from "utils/scripts/globalMessage"

const HTML5toTouch = {
  backends: [
    {
      id: "html5",
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: "touch",
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
}



export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isInPlanningMode = useSelector((state) => state.teamStatus.isInPlanningMode);
  const isCurrentPlanner = useSelector((state) => state.teamStatus.isCurrentPlanner);
  const discardingChanges = useSelector((state) => state.teamStatus.discardingChanged);
  const isCustomerAdmin = useSelector((state) => state.auth.role) === "customer_admin";
  const isOnlyLibrarian = useSelector((state) => state.auth.isOnlyLibrarian);
  const isAdmin = useSelector((state) => state.auth.role) === "admin";
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const IAPisRunning = useSelector((state) => state.waiting.iapLoading);
  const FillInisRunning = useSelector((state) => state.waiting.fillInLoading);
  const sampleAllocationIsRunning = useSelector((state) => state.waiting.sampleAllocating);
  const changingIncludeToday = useSelector((state) => state.teamStatus.changingIncludeToday);
  const plannertechIsReady = useSelector((state) => loadingState.isReady(state.loading));
  const showProjects = useSelector((state) => state.teamStatus.showProjects);
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses);
  const showForecast = useSelector((state) => state.teamStatus.showForecast);
  const unplanningProjects = useSelector((state) => state.projectActions.unplanning);
  const unplanningAnalyses = useSelector((state) => state.analyses.unplanning);
  const isUnplanning = unplanningAnalyses || unplanningProjects;
  const hasCompany = useSelector((state) => state.auth.companyId);
  const memberTimezone = useSelector((state) => state.auth.memberTimezone);
  const isMember = useSelector((state) => state.auth.isMember);
  const memberId = useSelector((state) => state.auth.memberId);
  const directToMemberPlan = useSelector((state) => state.teamStatus.directToMemberPlan);


  const showGlobalMessage = useSelector((state) => state.auth.showGlobalMessage);
  const globalMessage = useSelector((state) => state.auth.globalMessage);

  let sanitizedGlobalMessageHTML;
  if (showGlobalMessage) {
    const sanitizedGlobalMessage = DOMPurify.sanitize(globalMessage);
    sanitizedGlobalMessageHTML = displayWithLineBreaks(sanitizedGlobalMessage);
  }

  // Redirect if logged in and on /login
  useEffect(() => {
    if (isLoggedIn && location.pathname === "/login") {
      if (isAdmin || isCustomerAdmin) {
        navigate("/locations");
      } else {
        dispatch(teamStatus.fetchCurrent()).then((res) => {
          if (isOnlyLibrarian) {
            navigate("/librarian");
          } else if (isMember && res.options.directToMemberPlan) {
            navigate("/member/" + memberId);
          } else if (!isOnMobile && !isMember) {
            navigate("/suite");
          } else {
            navigate("/");
          }
        });
      }
    }
  }, [isLoggedIn, location.pathname, navigate, isAdmin, isCustomerAdmin, isOnlyLibrarian, isMember, memberId, directToMemberPlan, dispatch]);

  // Fetch current team status every 4 seconds (if logged in)
  useEffect(() => {
    const interval = window.setInterval(() => {
      if (isLoggedIn && !isAdmin && !isCustomerAdmin && !discardingChanges && document.visibilityState === "visible") {
        dispatch(teamStatus.fetchCurrent());
      }
    }, 4000);
    return () => window.clearInterval(interval);
  }, [dispatch, isLoggedIn, isAdmin, isCustomerAdmin, discardingChanges]);

  const handleInactivity = useCallback(() => {
    if (isLoggedIn) {
      dispatch(auth.logout());
    }
  }, [dispatch, isLoggedIn]);

  useInactivityTracker(handleInactivity, isLoggedIn, 8 * 60 * 60 * 1000);
  Settings.defaultZoneName = memberTimezone;

  useEffect(() => {
    function f(e) {
      if (isLoggedIn && isInPlanningMode && isCurrentPlanner) {
        e.preventDefault();
        e.returnValue = "";
        return "";
      }
    }
    window.addEventListener("beforeunload", f);
    return () => window.removeEventListener("beforeunload", f);
  }, [isLoggedIn, isInPlanningMode, isCurrentPlanner]);

  if (!isLoggedIn) {
    return (
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  } else if (isCustomerAdmin) {
    return (
      <>
        <Routes>
          <Route path="/customers/*" element={<CustomerAdministratorScreen />} />
          {hasCompany && <Route path="/locations/*" element={<AdministratorScreen />} />}
          <Route path="*" element={<Navigate to="/customers" />} />
        </Routes>
        {!isOnMobile && <ChatBot message={(message) => message} />}
      </>
    );
  } else if (isAdmin) {
    return (
      <>
        <Routes>
          <Route path="/locations/*" element={<AdministratorScreen />} />
          <Route path="*" element={<Navigate to="/locations" />} />
        </Routes>
        {!isOnMobile && <ChatBot message={(message) => message} />}
      </>
    );
  } else if (!plannertechIsReady) {
    return (
      <div className="AppLoading">
        <Loading title="LOADING" />
      </div>
    );
  } else if (isOnlyLibrarian) {
    return (
      <>
        <Routes>
          <Route path="/librarian" element={<LibrarianScreen />} />
          <Route path="*" element={<Navigate to="/librarian" />} />
        </Routes>
        {!isOnMobile && <ChatBot message={(message) => message} />}
      </>
    );
  }

  // Main routes
  return (
    <DndProvider options={HTML5toTouch}>
      <Routes>
        {!isOnMobile && !isMember && <Route path="/suite" element={<SuiteScreen />} />}
        <Route path="/librarian" element={<LibrarianScreen />} />
        <Route exact path="/" element={isOnMobile ? <ScheduleScreenMobile /> : <ScheduleScreen />} />
        {!showForecast && showAnalyses && <Route path="/sample-view" element={<SampleViewScreen />} />}
        <Route path="/asset-view" element={<AssetViewScreen />} />
        {showProjects && <Route path="/project-view" element={<ProjectViewScreen />} />}
        {showProjects && <Route path="/scrum-view" element={<ScrumViewScreen />} />}
        <Route path="/setup/*" element={<SetupScreen />} />
        {!showForecast && <Route exact path="/member/:memberId" element={isOnMobile ? <ScheduleScreenMobile /> : <SingleMemberScheduleScreen view="week-view" />} />}
        {!showForecast && <Route exact path="/member/:memberId/day" element={<SingleMemberScheduleScreen view="day-view" />} />}
        {!showForecast && <Route exact path="/member/:memberId/weekend" element={<SingleMemberScheduleScreen view="weekend-view" />} />}
        <Route path="/navigate" element={<ManageScreen />} />
      </Routes>
      {!isOnMobile && <ChatBot message={(message) => message} />}
      {showGlobalMessage && (
        <Popup className="plannertech-welcome-info-message" onCancel={() => dispatch(auth.changeShowGlobalMessage())}>
          <div dangerouslySetInnerHTML={{ __html: sanitizedGlobalMessageHTML }} />
        </Popup>
      )}
      <CustomDragLayer />
      {(IAPisRunning || sampleAllocationIsRunning || FillInisRunning) && (
        <div className="IapLoading">
          <Loading title={IAPisRunning ? IAPisRunning : FillInisRunning ? FillInisRunning : "Sample allocation"} />
        </div>
      )}
      {isUnplanning && (
        <div className="IapLoading">
          <Loading title={isUnplanning ? "Unplanning" : changingIncludeToday === 1 ? "Including today" : "Excluding today"} />
        </div>
      )}
    </DndProvider>
  );
}
